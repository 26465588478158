// Change <img> to a <div> with that image as a background - Replacement for object-fit: cover; which doesn't work on IE
// Keep in mind, you will need to specify a height as these will be empty <div>'s with no height
// The css selector to target this is div.js-img-cover and whatever other class you previously had on the <img> as those are carried over

// Get all image elements with the js-img-cover class on them
const coverImages = document.querySelectorAll('img.js-img-cover');

// Run function on page load
window.addEventListener('load', function() {
    convertCoverImages(coverImages);
});

convertCoverImages = (imageList) => {
    // Loop through list of images
    for (index = 0; index < imageList.length; ++index) {
        // Get image src value
        let imagePath = imageList[index].getAttribute('src');
        // Get image class list
        let imageClassList = imageList[index].classList;
        // Create new element to replace the image with
        let newDiv = document.createElement('div');

        // Add classes that were on the image to the new element
        for (i = 0; i < imageClassList.length; ++i) {
            newDiv.classList.add(imageClassList[i]);
        }
        // Add the image source as a background image url
        newDiv.style.backgroundImage = `url(${imagePath})`;

        // Replace the image with the new element we created
        imageList[index].parentNode.replaceChild(newDiv, imageList[index]);
    }
}